import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { LicenseManager } from 'ag-grid-enterprise';

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// LicenseManager.setLicenseKey(
//   'CompanyName=Insight_on_behalf_of_Chevron Technical Center (US, San Ramon, CA, 94583),LicensedGroup=CSCM_ AG_Grid,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=10,LicensedProductionInstancesCount=0,AssetReference=AG-034817,SupportServicesEnd=11_January_2024_[v2]_MTcwNDkzMTIwMDAwMA==2329806a6e0ccb05058157bb0868a449'
// );
LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-071432}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Chevron_Technical_Center_(US,_San_Ramon,_CA,_94583)}_is_granted_a_{Multiple_Applications}_Developer_License_for_{15}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{10_January_2026}____[v3]_[01]_MTc2ODAwMzIwMDAwMA==cdb01afe0e522e6b4a343ebbd6d8b391'
);
